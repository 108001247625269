
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import TargetModal from "@/components/modals/forms/institute/InstituteTarget.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
    TargetModal,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Institute",
          key: "institute",
          sortable: true,
        },
        {
          name: "Target Trainee",
          key: "target_trainee",
          sortable: true,
        },
        {
          name: "Batch Size",
          key: "batch_size",
          sortable: true,
        },
        {
          name: "# of Batches",
          key: "of_batch",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          course: "Mobile Application Development - Android",
          institute: "PencilBox Training Institute",
          target_trainee: "150",
          batch_size: "25 ",
          of_batch: "08",
        },
        {
          id: 2,
          course: "Graphics & UI Design",
          institute: "PencilBox Training Institute",
          target_trainee: "100",
          batch_size: "25 ",
          of_batch: "08",
        },
        {
          id: 3,
          course: "PHP with Laravel Framework",
          institute: "PencilBox Training Institute",
          target_trainee: "200",
          batch_size: "25 ",
          of_batch: "08",
        },
      ],
      data: {},
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
